@import url(https://fonts.googleapis.com/css2?family=Damion&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Flamenco&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Flamenco", cursive, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Damion", cursive;
  font-size: 4rem;
  font-weight: normal;
}

h2 {
  font-family: "Damion", cursive;
  font-size: 3rem;
  font-weight: normal;
}

h3 {
  font-family: "Damion", cursive;
  font-weight: normal;
  font-size: 2.25rem;
}

input,
textarea {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cards {
  display: flex;
}

.alert{
  position: absolute;
  top: 20%;
  right: 40%;
  background-color: lightgrey;
  opacity: 0.9;
  width: 450px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 5px;
  
}
.alert p{
  margin: 25px;
  font-size: 20px;
}
.alert button{
  width: 50px;
  height: 35px;
  font-size: 15px;
  color: white;
  background-color: black;
  opacity: 0.7;
  border-radius: 7px;
  margin: 5px
}


